
.scdp-log-item {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #F5F5F5;
    padding: 15px 30px;
}

.scdp-log-item:hover {
    box-shadow: 0 2px 10px rgba(33,33,33,.15);
    transform: scale(1.02);
    transition: all .2s ease-in-out;
}