body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

i.fa.fa-angle-left {
  font-style: normal;
}
i.fa.fa-angle-right {
  font-style: normal;
}
i.fa.fa-angle-left:after {
  content: "<";
}
i.fa.fa-angle-right:after {
  content: ">";
}
.ant-alert{
  z-index: 9999;
}