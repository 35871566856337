.modal .hrv-modal .modal-content {
    -webkit-box-shadow: 0 5px 10px 0 rgba(33, 33, 33, 0.1);
    box-shadow: 0 5px 10px 0 rgba(33, 33, 33, 0.1);
    border: 0;
    border-radius: 4px;
}

.modal .hrv-modal .modal-content .hrv-modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 20px;
    border-top: 0;
    border-bottom: 1px solid #DAE3EA;
}

.modal .hrv-modal .modal-content .hrv-modal-header .hrv-modal-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-transform: uppercase;
    margin-bottom: 0;
}

.modal .hrv-modal .modal-content .hrv-modal-header .hrv-close {
    padding: 0;
    margin: 0;
    line-height: initial;
    min-width: auto;
    cursor: pointer;
}

.modal .hrv-modal .modal-content .hrv-modal-header .hrv-close svg {
    top: 2px;
    fill: #9CA7B2;
}

.modal .hrv-modal .modal-content .hrv-modal-header .hrv-close:hover svg {
    fill: #474747;
}

.modal .hrv-modal .modal-content .hrv-modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 20px;
}

.modal .hrv-modal .modal-content .hrv-modal-body.hrv-modal-body-pd-0 {
    padding: 0;
}

.modal .hrv-modal .modal-content .hrv-modal-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 20px;
    border-top: 0;
    border-top: 1px solid #DAE3EA;
}

.modal .hrv-modal .modal-content .hrv-modal-footer>.col-auto {
    padding: 0
}

.modal .hrv-modal .modal-content .hrv-modal-footer .hrv-btn {
    margin-left: 10px;
    margin-right: 0;
}

.modal-backdrop {
    display: none;
    background-color: rgba(0, 0, 0, 0.25);
}

.modal-backdrop.show {
    display: block;
    opacity: 1;
    -webkit-animation: modalBgOpen 0.15s ease;
    animation: modalBgOpen 0.15s ease;
}

.modal.fade .hrv-modal.modal-dialog {
    transform: none;
    transition: none;
}

.modal.show .hrv-modal.modal-dialog {
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-animation: modalOpen 0.15s ease;
    animation: modalOpen 0.15s ease;
    transform: none;
}

.hrv-modal.modal-dialog {
    margin: 60px auto;
}

@media screen and (max-height: 480px) {
    .hrv-modal.modal-dialog {
        margin: 10px auto;
    }
}

@media (min-width: 992px) {
    .hrv-modal.modal-sm {
        max-width: 420px;
    }
    .hrv-modal.modal-md {
        max-width: 720px;
    }
    .hrv-modal.modal-lg {
        max-width: 960px;
    }
}

@-webkit-keyframes modalOpen {
    0% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        opacity: 0
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@keyframes modalOpen {
    0% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        opacity: 0
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@-webkit-keyframes modalBgOpen {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@keyframes modalBgOpen {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}